import { DateTimeFormatter, LocalDate } from "@js-joda/core";
import { format } from "date-fns";
import { DATE_FORMAT, FORMAT } from "../constants/datetime.constant";

export const formatDate = (date: Date) => {
  const formattedDate = format(date, FORMAT.yyyyMMdd);
  return formattedDate;
};

export const formatDateString = (date: string | number, format?: string) => {
  if (!date || date === "-") return "-";
  const _formattedDate = LocalDate.parse(
    date?.toString(),
    DATE_FORMAT.yyyyMMdd
  ).format(DateTimeFormatter.ofPattern(format ?? FORMAT.ddMMyyyy_dash));
  return _formattedDate;
};

export const formatTimestamp = (timestamp: number, dateFormat?: string) => {
  if (!timestamp) return "";
  let t = new Date(timestamp);
  return format(t, dateFormat ?? "dd/MM/yyyy HH:mm:ss");
};
