import { DateTimeFormatter } from "@js-joda/core";

export const FORMAT = {
  yyyyMMdd: "yyyyMMdd",
  ddMMyyyy_slash: "dd/MM/yyyy",
  mmddyyyy_slash: "MM/dd/yyyy",
  ddMMyyyy_dash: "dd-MM-yyyy",
  ddMyyyy_space: "dd M, yyyy",
};

export const DATE_FORMAT = {
  yyyyMMdd: DateTimeFormatter.ofPattern(FORMAT.yyyyMMdd),
};
