import axios from "axios";

export const API = axios.create({
  // baseURL: "http://192.168.90.248:8989/",
  // baseURL: "https://proxyapi.goracing.app/",
  baseURL: "https://demoproxyapi.ashva.app/",
  // baseURL: "https://foproxyapi.goracing.app:4443/",
});

// Backend modules to be reused
export const API_MOD = {
  REPORT: "report",
  OPERATOR: "operator",
  AGGREGATOR: "aggregator",
  USER: "user",
};
