import { Route, Routes } from "react-router";
import { ROUTES } from "./common/routes/default.routes";
import GlobalLayout from "./layouts/global.layout";
import AgentManagementPage from "./pages/operatorManagement/operatorManagement.page";
import FinancialDashboardPage from "./pages/reportManagement/reports/summaryLog/summaryLog.page";
import AggregatorManagementPage from "./pages/aggregatorManagement/aggregatorManagement.page";
import SignInPage from "../src/pages/auth/signIn.page";
import ThemeProvider from "./provider/theme.provider";
import ReportManagementPage from "./pages/reportManagement/reportManagement.page";
import UserManagementPage from "./pages/userManagement/userManagement.page";
import IndexPage from "./pages/index.page";
import TransactionLogPage from "./pages/reportManagement/reports/transactionLog.page";
import WagerReportPage from "./pages/reportManagement/reports/wagerReport.page";
import SettlementHistoryPage from "./pages/reportManagement/reports/settlementLog.page";
import AuditReport from "./pages/reportManagement/reports/auditReport.page";
import ReportProvider from "./provider/report.provider";
import DashboardPage from "./pages/dashboard/dashboard.page";
import DashboardLayout from "./layouts/dashboard.layout";
import DashboardProvider from "./provider/dashboard.provider";
import DataProvider from "./provider/data.provider";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  const getRoute = (Layout: any, Page: any, OtherLayout?: any) => {
    const PageTemplate = () => {
      if (OtherLayout)
        return (
          <OtherLayout>
            <Page />
          </OtherLayout>
        );
      return <Page />;
    };

    return (
      <Layout>
        <DashboardProvider>
          <ReportProvider>
            <DataProvider>{PageTemplate()}</DataProvider>
          </ReportProvider>
        </DashboardProvider>
      </Layout>
    );
  };

  return (
    <ThemeProvider>
      <ToastContainer />
      <Routes>
        <Route path={ROUTES.HOME} element={<IndexPage />} />
        <Route
          path={ROUTES.DASHBOARD}
          element={getRoute(GlobalLayout, DashboardPage, DashboardLayout)}
        />

        <Route
          path={ROUTES.USER_MANAGE}
          element={getRoute(GlobalLayout, UserManagementPage)}
        />
        <Route
          path={ROUTES.AGGREGATOR_MANAGE}
          element={getRoute(GlobalLayout, AggregatorManagementPage)}
        />
        <Route
          path={ROUTES.OPERATOR_MANAGE}
          element={getRoute(GlobalLayout, AgentManagementPage)}
        />
        <Route
          path={ROUTES.SUMMARY_LOG}
          element={getRoute(GlobalLayout, FinancialDashboardPage)}
        />

        <Route
          path={ROUTES.REPORT_MANAGE}
          element={getRoute(GlobalLayout, ReportManagementPage)}
        />
        <Route
          path={ROUTES.AUDIT_LOG}
          element={getRoute(GlobalLayout, AuditReport)}
        />
        <Route
          path={ROUTES.TRANSACTION_MANAGE}
          element={getRoute(GlobalLayout, TransactionLogPage)}
        />
        <Route
          path={ROUTES.WAGER_LOG}
          element={getRoute(GlobalLayout, WagerReportPage)}
        />
        {/* <Route
          path={ROUTES.DOCS}
          element={getRoute(GlobalLayout, DocumentationPage)}
        /> */}
        <Route
          path={ROUTES.SETTLEMENT_LOG}
          element={getRoute(GlobalLayout, SettlementHistoryPage)}
        />
        <Route path={ROUTES.SIGN_IN} element={<SignInPage />} />
        <Route path="*" element={<h1>404! Page Not Found!</h1>} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
