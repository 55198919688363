import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { REPORT_TAB } from "../../../common/routes/default.routes";
import FlatButton from "../../../components/elements/buttons/flat.button";
import { WagerReportColumns } from "../../../common/constants/columns.constant";
import {
  ColumnBodyOptions,
  ElementChangeEvent,
  PrimeTableClickDTO,
} from "../../../common/types/ui.types";
import RowDialog from "../../../components/shared/wagerRowDialog/wagerRowDialog";
import { FaFlag } from "react-icons/fa";
import { useRecoilState, useRecoilValue } from "recoil";
import { AtomWager, AtomWagerDate } from "../../../store/report.store";
import { FORMAT } from "../../../common/constants/datetime.constant";
import { toast } from "react-toastify";
import {
  formatDate,
  formatTimestamp,
} from "../../../common/helpers/date.helper";
import { API, API_MOD } from "../../../common/config/api.config";
import { format } from "date-fns";
import { AtomAuthUser, AtomShowSidebar } from "../../../store/auth.store";
import { WagerDTO } from "../../../common/models/report.model";
import { FiMenu } from "react-icons/fi";
import { checkError } from "../../../common/helpers/validation.helper";
import PrimeTable from "../../../components/ui/table/primeTable.ui";
import {
  NameIdTemplate,
  objToStringList,
  toLocaleNumber,
} from "../../../common/helpers/general.helper";
import styles from "./_reports.module.scss";
import { AtomAggregatorsObj } from "../../../store/aggregator.store";
import { AtomOperators } from "../../../store/operator.store";
import { ProvinceCodeToName } from "../../../common/data/provinces.data";
import ReportHeader from "../../../components/shared/ReportHeader/reportHeader.element";
import Filter from "../../../components/elements/Filter/filter.element";
import { __FilterDTO } from "../../../common/models/filter.modal";
import { FILTER } from "../../../common/constants/general.constant";
import { FilterHelper } from "../../../common/helpers/filter.helper";
import { GiVikingLonghouse } from "react-icons/gi";

const InitFilter = {
  [FILTER.betStatus]: { options: [], selected: [] },
  [FILTER.aggregator]: { options: [], selected: [] },
  [FILTER.operator]: { options: [], selected: [] },
  [FILTER.wagerCurrency]: { options: [], selected: [] },
  [FILTER.poolType]: { options: [], selected: [] },
  [FILTER.raceKey]: { options: [], selected: [] },
  [FILTER.connectorId]: { options: [], selected: [] },
};

const WagerReportPage = () => {
  const [rowShow, setRowShow] = useState(false);
  const [selectedRow, setSelectedRow] = useState({} as WagerDTO);
  const path = useLocation().pathname;
  const [filteredWager, setFilteredWager] = useState<WagerDTO[]>([]);
  const [search, setSearch] = useState("");

  const [wager, setWager] = useRecoilState(AtomWager);
  const [selectedDate, setSelectedDate] = useRecoilState(AtomWagerDate);
  const authUser = useRecoilValue(AtomAuthUser);
  const [isOpen, setIsOpen] = useRecoilState(AtomShowSidebar);
  const [, setLoader] = useState(false);

  const navigate = useNavigate();
  const aggregators = useRecoilValue(AtomAggregatorsObj);
  const operators = useRecoilValue(AtomOperators);
  const [filterData, setFilterData] = useState<__FilterDTO>(InitFilter);
  const [filterShow, setFilterShow] = useState(false);

  useEffect(() => {
    if (wager.length === 0) onSubmit();

    console.log(selectedRow.poolPayout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRowClick = (e: PrimeTableClickDTO) => {
    setSelectedRow(e.value);
    setRowShow(true);
  };
  const timeStamp = selectedRow?.timestamp;
  let timeStemp = +timeStamp ?? "-";

  const onClose = () => {
    setRowShow(false);
  };

  const onSubmit = () => {
    setLoader(true);
    let selectStartDate = selectedDate.startDate;
    let selectEndDate = selectedDate.endDate;
    let dateFirst = new Date(format(selectStartDate, FORMAT.mmddyyyy_slash));
    let dateSecond = new Date(format(selectEndDate, FORMAT.mmddyyyy_slash));
    let timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (diffDays > 30) {
      toast.error("Start Date Should Not Be More Than 30 Days", {
        bodyClassName: "text",
        className: "text",
        position: "bottom-right",
      });
      return;
    }
    if (dateFirst > dateSecond) {
      toast.error("End date should not be greater than start date", {
        bodyClassName: "text",
        className: "text",
        position: "bottom-right",
      });
      return;
    }
    let startDate = formatDate(selectStartDate);
    let endDate = formatDate(selectEndDate);
    let transactionPayload: any = {
      startDate: startDate.toString(),
      endDate: endDate.toString(),
    };
    API.post(`${API_MOD.REPORT}/wagers`, transactionPayload, {
      headers: {
        Authorization: `${authUser?.token}`,
      },
    })
      .then((res) => {
        let response = (res?.data as WagerDTO[]) ?? [];
        const _sorted = response.sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : a.timestamp > b.timestamp ? -1 : 0
        );
        setWager(_sorted);
        setLoader(false);
        setFilteredWager(_sorted);
      })
      .catch((error) => {
        setLoader(false);
        checkError(error, true, navigate);
        setWager([]);
        setFilteredWager([]);
      });
  };

  const handleDateChange = (date: any, name: any) => {
    let _date = new Date(date);

    if (name === "startDate" && _date > selectedDate.endDate) {
      setSelectedDate({
        endDate: _date,
        startDate: _date,
      });
    } else {
      setSelectedDate({ ...selectedDate, [name]: _date });
    }
  };
  const poolkey = selectedRow?.raceKey;
  let _splitKey = poolkey?.split("~") ?? [];
  let _poolTrackInfo = _splitKey[1]?.split("-") ?? [];

  const ColumnBody = (rowData: WagerDTO, { field }: ColumnBodyOptions) => {
    let _data = rowData[field] ?? "-";

    switch (field) {
      case "timestamp":
        return <>{formatTimestamp(Number(_data))}</>;
      case "aggregatorId":
        const name =
          aggregators[rowData.aggregatorId] &&
          aggregators[rowData.aggregatorId].detail
            ? aggregators[rowData.aggregatorId].detail
            : rowData.aggregatorId;
        return NameIdTemplate(name, rowData.aggregatorId);
      case "operatorId":
        const data = rowData.isDefault === true ? aggregators : operators;
        const _name =
          data[rowData.operatorId] && data[rowData.operatorId].detail
            ? data[rowData.operatorId].detail
            : rowData.operatorId;
        return (
          <>
            {NameIdTemplate(
              _name,
              rowData.operatorId,
              rowData?.operatorId === rowData.aggregatorId
            )}
          </>
        );
      case "successBetAmt":
        return <div className={styles.alignRight}>{toLocaleNumber(_data?.toFixed(2))}</div>;
      case "totalInvested":
        return <div className={styles.alignRight}>{toLocaleNumber(_data?.toFixed(2))}</div>;
      case "totalWinnings":
        return <div className={styles.alignRight}>{toLocaleNumber(_data?.toFixed(2))}</div>;
      case "totalRefunds":
        return <div className={styles.alignRight}>{toLocaleNumber(_data?.toFixed(2))}</div>;
      case "pnl":
        return <div className={styles.alignRight}>{toLocaleNumber(_data?.toFixed(2))}</div>;
      default:
        return <>{_data ?? "-"}</>;
    }
  };
  const rowDataTemp = () => {
    return <h5 className={styles.dialogHeader}>Wager Details</h5>;
  };
  const handleMenuOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleRoute = (route: string) => {
    navigate(route);
  };

  const handleChange = (e: ElementChangeEvent) => {
    const val = e.data.toLowerCase();

    setSearch(e.data);
    initializeOptions(wager);

    const _filteredData = [...wager].filter((c) => {
      let aggregator = aggregators[c.aggregatorId]?.detail;
      let operator = operators[c.operatorId]?.detail;

      const isValid =
        aggregator?.toLowerCase().startsWith(val) ||
        operator?.toLowerCase().startsWith(val) ||
        c.aggregatorId?.toLowerCase().startsWith(val) ||
        c.operatorId?.toLowerCase().startsWith(val) ||
        c.clientId?.toLowerCase().startsWith(val) ||
        c.connectorId?.toLowerCase().startsWith(val) ||
        c.poolCurrency?.toLowerCase().startsWith(val) ||
        c.betStatus?.toLowerCase().startsWith(val) ||
        c.roundId?.toLowerCase().startsWith(val) ||
        c.poolType?.toLowerCase().startsWith(val) ||
        c.raceKey?.toLowerCase().startsWith(val) ||
        aggregator?.toLowerCase().includes(val) ||
        operator?.toLowerCase().includes(val) ||
        c.aggregatorId?.toLowerCase().includes(val) ||
        c.operatorId?.toLowerCase().includes(val) ||
        c.clientId?.toLowerCase().includes(val) ||
        c.connectorId?.toLowerCase().includes(val) ||
        c.poolCurrency?.toLowerCase().includes(val) ||
        c.betStatus?.toLowerCase().includes(val) ||
        c.poolType?.toLowerCase().includes(val) ||
        c.raceKey?.toLowerCase().includes(val) ||
        c.roundId?.toLowerCase().includes(val) ||
        formatTimestamp(Number(c.timestamp)).includes(val) ||
        formatTimestamp(Number(c.timestamp)).startsWith(val);
      return isValid;
    });
    setFilteredWager(_filteredData);
  };

  const initializeOptions = useCallback((wager: WagerDTO[]) => {
    const _currency = objToStringList(wager, "wagerCurrency");
    const _betStatus = objToStringList(wager, "betStatus");
    const _aggregator = objToStringList(wager, "aggregatorId");
    const _operator = objToStringList(wager, "operatorId");
    const _poolType = objToStringList(wager, "poolType");
    const _raceKey = objToStringList(wager, "raceKey");
    const _connector = objToStringList(wager, "connectorId");

    setFilterData({
      ...filterData,
      aggregator: { ...filterData.aggregator, options: _aggregator },
      operator: { ...filterData.operator, options: _operator },
      wagerCurrency: { ...filterData.wagerCurrency, options: _currency },
      betStatus: { ...filterData.betStatus, options: _betStatus },
      poolType: { ...filterData.poolType, options: _poolType },
      raceKey: { ...filterData.raceKey, options: _raceKey },
      connectorId: { ...filterData.connectorId, options: _connector },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initializeOptions(wager);

    handleChange({ data: search, name: "" });
    // setFilteredWager(wager);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wager]);

  const handleFilterChange = (e: ElementChangeEvent) => {
    let _filter = { ...filterData };
    _filter = { ..._filter, [e.name]: e.data };
    setFilterData(_filter);
  };

  const onFilterClick = () => {
    setSearch("");
    const _currency = filterData[FILTER.wagerCurrency];
    const _betStatus = filterData[FILTER.betStatus];
    const _operatorFilter = filterData[FILTER.operator];
    const _aggregatorFilter = filterData[FILTER.aggregator];
    const _poolFilter = filterData[FILTER.poolType];
    const _raceKey = filterData[FILTER.raceKey];
    const _connector = filterData[FILTER.connectorId];
    let _filteredTransactions: WagerDTO[] = [];

    for (const wr of wager) {
      if (
        _currency &&
        _currency?.selected?.length &&
        !_currency.selected.includes(wr.wagerCurrency)
      )
        continue;
      if (
        _operatorFilter &&
        _operatorFilter?.selected?.length &&
        !_operatorFilter.selected.includes(wr.operatorId)
      )
        continue;
      if (
        _aggregatorFilter &&
        _aggregatorFilter?.selected?.length &&
        !_aggregatorFilter.selected.includes(wr.aggregatorId)
      )
        continue;
      if (
        _betStatus &&
        _betStatus?.selected?.length &&
        !_betStatus.selected.includes(wr.betStatus)
      )
        continue;
      if (
        _poolFilter &&
        _poolFilter?.selected?.length &&
        !_poolFilter.selected.includes(wr.poolType)
      )
        continue;
      if (
        _raceKey &&
        _raceKey?.selected?.length &&
        !_raceKey.selected.includes(wr.raceKey)
      )
        continue;
      if (
        _connector &&
        _connector?.selected?.length &&
        !_connector.selected.includes(wr.connectorId)
      )
        continue;
      _filteredTransactions.push(wr);
    }
    setFilteredWager(_filteredTransactions);
  };
  const handleFilter = () => {
    setFilterShow(!filterShow);
  };

  const onClearClick = () => {
    initializeOptions(wager);
    setSearch("");
    setFilteredWager(Object.values(wager));
  };

  const filterSearch = (e: ElementChangeEvent, type?: string) => {
    switch (type) {
      case FILTER.aggregator:
        const filterResult = FilterHelper.Aggregator(
          e.data,
          wager,
          aggregators
        );
        const _aggregators = objToStringList(filterResult, "aggregatorId");

        setFilterData({
          ...filterData,
          aggregator: { ...filterData.aggregator, options: _aggregators },
        });
        break;
      case FILTER.operator:
        const _filterResult = FilterHelper.Operator(e.data, wager, operators);
        const _operator = objToStringList(_filterResult, "operatorId");

        setFilterData({
          ...filterData,
          operator: { ...filterData.operator, options: _operator },
        });
        break;
      case FILTER.currency:
        const currencyRes = FilterHelper.Currency(e.data, wager);
        const _currency = objToStringList(currencyRes, "currency");
        setFilterData({
          ...filterData,
          currency: { ...filterData.currency, options: _currency },
        });
        break;
      case FILTER.betStatus:
        const betStatusRes = FilterHelper.BetStatus(e.data, wager);
        const _betStatus = objToStringList(betStatusRes, "betStatus");
        setFilterData({
          ...filterData,
          betStatus: { ...filterData.betStatus, options: _betStatus },
        });
        break;
      case FILTER.poolType:
        const poolTypeRes = FilterHelper.PoolType(e.data, wager);
        const _poolType = objToStringList(poolTypeRes, "poolType");
        setFilterData({
          ...filterData,
          poolType: { ...filterData.poolType, options: _poolType },
        });
        break;
      case FILTER.raceKey:
        const raceKeyRes = FilterHelper.RaceKey(e.data, wager);
        const _raceKey = objToStringList(raceKeyRes, "raceKey");
        setFilterData({
          ...filterData,
          raceKey: { ...filterData.raceKey, options: _raceKey },
        });
        break;
      case FILTER.connectorId:
        const connectorIdRes = FilterHelper.RaceKey(e.data, wager);
        const _connectorId = objToStringList(connectorIdRes, "connectorId");
        setFilterData({
          ...filterData,
          connectorId: { ...filterData.connectorId, options: _connectorId },
        });
        break;

      default:
        break;
    }
  };

  return (
    <div>
      <header className={styles.headerWrapper1}>
        <div className={styles.menuIcon}>
          <FiMenu
            onClick={handleMenuOpen}
            size={40}
            className="burgerMenuIcon"
          />
        </div>
        {REPORT_TAB.map((tab, key) => {
          const isActive = path.startsWith(tab.href);
          if (tab.roles?.includes(authUser?.role ?? ""))
            return (
              <FlatButton
                key={key}
                label={tab.label}
                className={`${styles.iconSet} ${isActive && styles.activeLink}`}
                onClick={() => handleRoute(tab.href)}
              />
            );
          return null;
        })}
      </header>
      <ReportHeader
        selectedDate={selectedDate.startDate}
        onSubmit={onSubmit}
        onDateChange={(date) => handleDateChange(date, "startDate")}
        InputChange={handleChange}
        onFilter={handleFilter}
        search={search}
        onEndDateChange={(date) => handleDateChange(date, "endDate")}
        selectedEndDate={selectedDate.endDate}
        exportData={filteredWager}
        exportFileName={`WagerReport_${formatDate(
          selectedDate.startDate
        )}_${formatDate(selectedDate.endDate)}`}
      />
      <Filter
        show={filterShow}
        filterData={filterData}
        onFilterCHange={handleFilterChange}
        onFilterClick={onFilterClick}
        onClearClick={onClearClick}
        onFilter={filterSearch}
      />
      <RowDialog
        closeOnEsc
        hideCloseBtn={false}
        show={rowShow}
        setShow={setRowShow}
        headerTemplate={rowDataTemp}
        glassEffect={true}
      >
        <div>
          <div className={styles.headerWrapper}>
            <h4 className={styles.countryName}>
              <span
                className={`ip2location-flag-32 flag-${
                  selectedRow?.province?.toLowerCase() ?? ""
                }`}
              ></span>
              <div className={styles.headerContainer}>
                <span className={styles.wagerName}>
                  {_poolTrackInfo[1]} - R{_splitKey[2]}
                </span>
                <span className={styles.trackInfo}>
                  <span className={styles.value}>
                    {ProvinceCodeToName[selectedRow.province]} |{" "}
                    {_poolTrackInfo[2]}
                  </span>
                </span>
              </div>
            </h4>
            <div className={styles.tidWrapper}>
              <span className={styles.TIDLabel}>T_ID:&nbsp;</span>
              <span className={styles.TIDValue}>
                {selectedRow?.transactionId}
              </span>
            </div>
          </div>
          <div className={styles.bodyWrapper}>
            <div className={`${styles.cardRow} ${styles.cardBodyBack}`}>
              <div className={styles.raceInfo}>
                <span className={styles.label}>Time</span>
                <span className={styles.value}>
                  {formatTimestamp(timeStemp) ?? "-"}
                </span>
              </div>
              <div className={styles.raceInfo}>
                <span className={styles.label}>Pool Type</span>
                <span className={styles.value}>
                  {selectedRow?.poolType ?? "-"}
                </span>
              </div>
              <div className={styles.statusWrapper}>
                <div className={styles.middleCard}>
                  <span className={styles.label}>Bet Status</span>
                  <span className={styles.value}>
                    {selectedRow?.betStatus ?? "-"}
                  </span>
                </div>
                <div className={styles.middleCard}>
                  <span className={styles.pnlLabel}>PNL</span>
                  <span
                    className={
                      selectedRow?.pnl >= 0
                        ? styles.pnlValue
                        : styles.pnlNegativeValue
                    }
                  >
                    {selectedRow?.pnl?.toFixed(2) ?? "-"}
                  </span>
                </div>
              </div>
            </div>
            <div className={`${styles.cardRow} ${styles.spaceBetween}`}>
              <div className={`${styles.raceInfo} ${styles.cardInfo}`}>
                <span className={styles.label}>Combinations</span>
                {selectedRow?.combinations?.map(
                  (comb: any, index: number, key: any) => {
                    return (
                      <>
                        <div className={styles.combinations} key={key}>
                          <p>{++index}:</p>
                          <span className={styles.value}>
                            {JSON.stringify(comb).replace(/[[\]']+/g, "")}
                          </span>
                        </div>
                      </>
                    );
                  }
                )}
              </div>
              <div className={`${styles.raceInfo} ${styles.cardInfo}`}>
                <span className={styles.label}>Investment</span>
                <span className={styles.value}>
                  {selectedRow?.totalInvested?.toFixed(2) ?? "-"}
                </span>
              </div>
              <div className={`${styles.raceInfo} ${styles.cardInfo}`}>
                <span className={styles.label}>Selection Amount</span>
                <span className={styles.value}>
                  {selectedRow?.selectionAmt?.toFixed(2)}
                </span>
              </div>
            </div>

            <div className={styles.cardRow}>
              <div className={styles.footer}>
                <span className={styles.winningLabel}>Total Winnings</span>
                <span
                  className={
                    selectedRow?.totalWinnings >= 0
                      ? `${styles.value} ${styles.winningValue}`
                      : `${styles.value} ${styles.winningValue} ${styles.winningNegativeValue}`
                  }
                >
                  {selectedRow?.totalWinnings?.toFixed(2) ?? "-"}
                </span>
              </div>
              <div>
                <div>
                  <span className={styles.label}>Connector:&nbsp;</span>
                  <span className={styles.value}>
                    {selectedRow?.connectorId}
                  </span>
                </div>
              </div>
            </div>

              <div className={styles.grid1}>
                <div className={styles.cardHeader}>Actual Race Results</div>
                  {/* <div className={styles.closeBtnWrapper}>
                    <FlatButton className={styles.closeBtn} onClick={onClose}>
                      Close
                    </FlatButton>
                  </div> */}
              </div>
              <div className={styles.actualResult}>
              <div className={styles.resultsBody}>
                <table className={styles.table}>
                  <thead>
                    <tr className={styles.headingRow}>
                      <th className={styles.heading}>Selections</th>
                      <th className={styles.heading}>Odds</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedRow.poolPayout &&
                      Object.keys(selectedRow.poolPayout).map((i) => {
                        return (
                          <tr className={styles.tableBody}>
                            <td className={styles.item}>{i}</td>
                            <td className={styles.item}>
                              {selectedRow?.poolPayout[i]}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
        
         
          </div>
        </div>
      </RowDialog>

      <PrimeTable
        data={filteredWager}
        columns={WagerReportColumns}
        ColumnBody={ColumnBody}
        onRowSelect={onRowClick}
        isLoading={false}
      />
    </div>
  );
};

export default WagerReportPage;
