import { memo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./sidebarNavigation.module.scss";
import { CgProfile } from "react-icons/cg";
import { IoIosArrowForward } from "react-icons/io";
import FlatButton from "../../elements/buttons/flat.button";
import { FaSignOutAlt } from "react-icons/fa";
import { ROUTES } from "../../../common/routes/default.routes";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import {
  AtomAuthUser,
  AtomShowSidebar,
  SelectorResetAllAtoms,
} from "../../../store/auth.store";
import { AiFillCloseCircle } from "react-icons/ai";
import logo from "../../../assets/images/logo.png";
import ConfirmDialog from "../../ui/dialog/confirmDialog";
export interface NavigationMenu {
  label?: string;
  href: string;
  icon: any;
  target?: string;
}
interface SideNavigationProps {
  menu: NavigationMenu[];
}
//* SideNavigation
const SideNavigation = memo(({ menu }: SideNavigationProps) => {
  const resetAllAtoms = useResetRecoilState(SelectorResetAllAtoms);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useRecoilState(AtomShowSidebar);
  const [show, setShow] = useState(false); // toggle log out popup for confirmation.
  const path = useLocation().pathname; // current route path name
  const authUser = useRecoilValue(AtomAuthUser); // Authenticated user.

  const onClose = () => {
    if (window.innerWidth <= 1440) {
      setIsOpen(false);
    }
  };

  const onLogout = () => {
    localStorage.clear();
    resetAllAtoms();
    navigate(ROUTES.SIGN_IN);
  };

  return (
    <>
      {/* hello */}
      <div className={styles.blurBg}>
        <div
          className={`${styles.sidebarWrapper} ${
            isOpen ? styles.shown : styles.hidden
          }`}
        >
          <div className={styles.closeBtn}>
            <AiFillCloseCircle onClick={onClose} size={30} />
          </div>
          <div className={styles.navLinksWrapper}>
            <Link to="/dashboard">
              <div className="text-center">
                <img src={logo} className={styles.logo} alt="Go Racing Logo" />
              </div>
            </Link>
            <nav className={styles.navWrapper}>
              {menu.map((menu, key) => {
                const isActive = path.startsWith(menu.href);
                if (menu.target)
                  return (
                    <a href={menu.href} key={menu.href} target={menu.target}>
                      <div
                        className={`${styles.iconSet} ${
                          isActive && styles.activeLink
                        }`}
                      >
                        <div className={styles.iconBorderSet}>
                          <menu.icon key={key} size={20} />
                        </div>
                        <FlatButton
                          onClick={onClose}
                          key={key}
                          className={styles.link}
                        >
                          {menu.label}
                        </FlatButton>
                      </div>
                    </a>
                  );
                return (
                  <Link to={menu.href} key={menu.href}>
                    <div
                      className={`${styles.iconSet} ${
                        isActive && styles.activeLink
                      }`}
                    >
                      <div className={styles.iconBorderSet}>
                        <menu.icon key={key} size={20} />
                      </div>
                      <FlatButton
                        onClick={onClose}
                        key={key}
                        className={styles.link}
                      >
                        {menu.label}
                      </FlatButton>
                    </div>
                  </Link>
                );
              })}

              <div className={`${styles.iconSet} `}>
                <div className={styles.iconBorderSet}>
                  <FaSignOutAlt size={20} />
                </div>
                {/* <LogoutDialog setShow={setShow} show={show} /> */}

                <FlatButton
                  onClick={() => setShow(true)}
                  className={styles.link}
                >
                  Log Out
                </FlatButton>

                <ConfirmDialog
                  title={"Logout Confirmation"}
                  message={"Are you sure you want to logout?"}
                  show={show}
                  setShow={setShow}
                  onConfirm={onLogout}
                />
              </div>
            </nav>
          </div>
          <div className={styles.footerWrapper}>
            <div className={styles.leftMenu}>
              <CgProfile size={50} style={{ color: "#186AA5" }} />
            </div>
            <div className={styles.rightMenu}>
              <span className={styles.adminLabel}>{authUser?.username}</span>
              <span className={styles.superAdLabel}>
                <IoIosArrowForward size={18} style={{ color: "#186AA5" }} />
                {authUser?.role}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

//* Display name for memoization.
SideNavigation.displayName = "SideNavigation";
export default SideNavigation;
