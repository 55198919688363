import { useCallback, useEffect, useState } from "react";
import { GoPrimitiveDot } from "react-icons/go";
import { RiDeleteBin6Line } from "react-icons/ri";
import { ElementChangeEvent } from "../../common/types/ui.types";
import FlatButton from "../../components/elements/buttons/flat.button";
import SearchBar from "../../components/ui/searchBar/searchBar";
import { OperatorColumn } from "../../common/constants/columns.constant";
import Dialog from "../../components/ui/dialog/dialog.ui";
import InputText from "../../components/elements/input/inputText.element";
import Dropdown from "../../components/elements/dropdown/dropdown.element";
import { useRecoilState, useRecoilValue } from "recoil";
import { OperatorDTO } from "../../common/models/operator.model";
import { AtomAggregatorsObj } from "../../store/aggregator.store";
// import CurrencyDropdown from "../components/shared/sideNavigation/currencyDropdown/currency.dropdown";
import { API, API_MOD } from "../../common/config/api.config";
import { AtomAuthUser, AtomShowSidebar } from "../../store/auth.store";
import { toast } from "react-toastify";
import { AtomOperators } from "../../store/operator.store";
import { FILTER, ROLES, STATUS } from "../../common/constants/general.constant";
import { useNavigate } from "react-router";
import { FaFilter, FaGlobe, FaPercent, FaUser } from "react-icons/fa";
import { MdEdit, MdPersonAddAlt1 } from "react-icons/md";
import { FiMenu } from "react-icons/fi";
import {
  checkError,
  isValidAgent,
} from "../../common/helpers/validation.helper";
import { showToast } from "../../common/helpers/toast.helper";

import PrimeTable from "../../components/ui/table/primeTable.ui";
import ConfirmDialog from "../../components/ui/dialog/confirmDialog";
import {
  generateUniqueId,
  isLessWidth,
  NameIdTemplate,
  objToStringList,
} from "../../common/helpers/general.helper";
import { CgProfile } from "react-icons/cg";
import { IoWalletOutline } from "react-icons/io5";
import styles from "./operatorManagement.module.scss";
import { __FilterDTO } from "../../common/models/filter.modal";
import Filter from "../../components/elements/Filter/filter.element";
const agentStatus = [STATUS.ACTIVE, STATUS.INACTIVE];
// const currency = ["USD", "HKD", "AU"];

const InitFilter = {
  // [FILTER.aggregator]: { options: [], selected: [] },
  // [FILTER.operator]: { options: [], selected: [] },
  [FILTER.status]: { options: [], selected: [] },
};

//* AgentManagements
const AgentManagement = () => {
  // VARIABLES
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [agentDetails, setAgentDetails] = useState<OperatorDTO>(
    {} as OperatorDTO
  );
  const [selectedRow, setSelectedRow] = useState<OperatorDTO>(
    {} as OperatorDTO
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [filteredOperators, setFilteredOperators] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useRecoilState(AtomShowSidebar);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showEditRebateRateDialog, setShowEditRebateRateDialog] =
    useState(false);
  const [editValue, setEditValue] = useState(null);
  const [editDetail, setEditDetail] = useState(null);
  const [editRebateRateValue, setEditRebateRateValue] = useState(null);
  const [showStatusDialog, setShowStatusDialog] = useState(false);
  const [loader, setLoader] = useState(false);
  const [filterData, setFilterData] = useState<__FilterDTO>(InitFilter);
  const [filterShow, setFilterShow] = useState(false);

  const navigate = useNavigate();

  // RECOIL VARIABLES
  const operators = useRecoilValue(AtomAggregatorsObj); // Operators object;
  const authUser = useRecoilValue(AtomAuthUser);
  const [operatorState, setOperatorState] = useRecoilState(AtomOperators);

  // CALLBACKS
  const [idOptions, setIdOptions] = useState<{
    options: any[];
    disabled?: boolean;
  }>({
    options: [],
    disabled: false,
  }); // Dynamic options.

  useEffect(() => {
    const role = authUser?.role;
    if (role) {
      if (role === ROLES.AGGREGATOR) {
        setAgentDetails({
          ...agentDetails,
          aggregatorId: authUser.aggregatorId,
        });
        setIdOptions({
          options: [{ aggregatorId: authUser.aggregatorId }],
          disabled: true,
        });
      } else if (role === ROLES.ADMIN) {
        setIdOptions({ options: Object.values(operators), disabled: false });
      }
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, operators]);

  const initializeOptions = useCallback(() => {
    // const _agents = Object.values(operatorState).filter((a) => !a.isDefault);
    // const _aggregatorId = objToStringList(
    //   Object.values(operatorState),
    //   "aggregatorId"
    // );
    // const _operatorId = objToStringList(
    //   Object.values(operatorState),
    //   "operatorId"
    // );
    // const _filteredOperator: OperatorDTO[] = [];
    const _statusFilter = [STATUS.ACTIVE, STATUS.INACTIVE];
    setFilterData({
      ...filterData,
      [FILTER.status]: { ...filterData.status, options: _statusFilter },
      // operators: { ...filterData.operators, options: _operatorId },
    });
    // setFilteredOperators(_filteredOperator);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operatorState]);

  const handleFilterChange = (e: ElementChangeEvent) => {
    let _filter = { ...filterData };
    _filter = { ..._filter, [e.name]: e.data };
    setFilterData(_filter);
  };

  const onFilterClick = () => {
    const _statusFilter = filterData[FILTER.status];
    let _filteredUsers: OperatorDTO[] = [];
    let _op = Object.values(operatorState);
    for (const user of _op) {
      if (
        _statusFilter &&
        _statusFilter?.selected?.length &&
        !_statusFilter.selected.includes(user.status)
      )
        continue;
      _filteredUsers.push(user);
    }
    setFilteredOperators(_filteredUsers);
    setSearch("");
  };

  useEffect(() => {
    initializeOptions();
    resetOpertors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operatorState]);

  const resetOpertors = () => {
    setFilteredOperators(
      Object.values(operatorState).filter((x) => !x.isDefault)
    );
  };

  const onClearClick = () => {
    // setFilterData({});
    initializeOptions();
    setSearch("");
    resetOpertors();
  };

  // GENERAL FUNCTIONS
  const onSave = () => {
    setLoader(true);
    if (!isValidAgent(agentDetails, setErrorMessage)) return;
    // Add agent api call
    let _agentObj = { ...agentDetails } as any;
    _agentObj["environment"] = _agentObj["environment"] =
      _agentObj["environment"] === STATUS.LIVE
        ? true
        : _agentObj["environment"] === STATUS.SIM
        ? false
        : "-";
    // _agentObj["creditLimit"] = Number(agentDetails.creditLimit);
    _agentObj["rebateRate"] = Number(agentDetails.rebateRate);
    _agentObj["originUrls"] = [agentDetails.originUrls.toString()];

    API.post(`${API_MOD.OPERATOR}/add`, _agentObj, {
      headers: {
        Authorization: `${authUser?.token}`,
      },
    })
      .then((res) => {
        if (res) {
          setOperatorState({
            ...operatorState,
            [_agentObj.operatorId]: _agentObj,
          });
          setShow(false);
          setFilteredOperators([agentDetails, ...filteredOperators]);
          setLoader(false);
          if (authUser?.role === ROLES.AGGREGATOR) {
            setAgentDetails({
              aggregatorId: authUser.aggregatorId,
            } as OperatorDTO);
          } else {
            setAgentDetails({} as OperatorDTO);
          }
          toast.success("Operator added successfully..!", {
            bodyClassName: "text",
            className: "text",
            position: "bottom-right",
          });
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error, "Error");
        checkError(error, true, navigate);
      });
  };

  const operatorOptTemplate = (option: any, opKey?: string) => {
    const _name = option.detail
      ? option.detail
      : typeof option === "object" && opKey
      ? option[opKey]
      : option;
    // return "test";
    return NameIdTemplate(_name, option.aggregatorId);
  };

  const onClose = () => {
    setShow(false);
    setAgentDetails({} as OperatorDTO);
    setErrorMessage("");
    setShowEditDialog(false);
    // setShowEditCreditDialog(false);
    setShowEditRebateRateDialog(false);
    setShowStatusDialog(false);
  };

  const ColumnBody = (rowData: OperatorDTO | any, { field, rowIndex }: any) => {
    const _data = rowData[field];

    switch (field) {
      case "Sr_no":
        return <>{++rowIndex}</>;

      case "detail":
        return NameIdTemplate(rowData.detail, rowData.operatorId);
      case "aggregatorId":
        const name = operators[rowData?.aggregatorId]
          ? operators[rowData?.aggregatorId].detail
          : rowData?.aggregatorId ?? "";

        return NameIdTemplate(name, rowData.aggregatorId);
      case "originUrls":
        return (
          <>
            <div className={styles.editBtnWpr}>
              {rowData?.originUrls}
              <button
                className={styles.editBtn}
                onClick={() => handleEditDialog(rowData)}
              >
                <MdEdit size={15} />
              </button>
            </div>
          </>
        );
      case "rebateRate":
        return (
          <>
            <div className={`${styles.editBtnWpr} ${styles.alignRight}`}>
              {rowData?.rebateRate
                ? `${rowData.rebateRate?.toFixed(2)} %`
                : "-"}
              <button
                className={styles.editBtn}
                onClick={() => handleRebateRateEditDialog(rowData)}
              >
                <MdEdit size={15} />
              </button>
            </div>
          </>
        );
      case "status":
        return (
          <>
            <div
              className={`${styles.statusWrapper} ${styles[_data]}`}
              onClick={() => handleStatusChange(rowData)}
            >
              <GoPrimitiveDot size={25} />
              <FlatButton className={styles.statusBtn}>
                {rowData.status}
              </FlatButton>
            </div>
          </>
        );
      case "action":
        if (authUser?.operatorId === rowData?.operatorId) return null;
        return (
          <>
            <FlatButton
              className={styles.actionBtn}
              onClick={() => handleConfirmDelete(rowData)}
              size="xs"
              severity="warning"
              // isDisable={authUser?.operatorId === rowData?.operatorId}
            >
              <RiDeleteBin6Line />
            </FlatButton>
          </>
        );
      default:
        return <>{rowData[field] ?? "-"}</>;
    }
  };

  const dialogHeader = () => {
    return <h1>Add Operator</h1>;
  };

  const handleStatusChange = (rowData: OperatorDTO) => {
    setShowStatusDialog(true);
    setSelectedRow(rowData);
  };

  // CHANGE FUNCTIONS

  const handleChange = (e: ElementChangeEvent, valueKey?: string) => {
    let _data = e.data ?? "";
    if (valueKey && _data) {
      _data = _data ? _data[valueKey] : "";
    }
    setErrorMessage("");
    setAgentDetails({ ...agentDetails, [e.name]: _data ?? "" });
  };
  const onChange = (e: ElementChangeEvent) => {
    initializeOptions();
    const lowerCase = e.data.toLowerCase();
    setSearch(e.data);
    const filterEntries = Object.values(operatorState ?? {}).filter(
      (operator) => {
        let aggregator = operators[operator.aggregatorId]?.detail;
        if (!operator.isDefault)
          return (
            operator.detail.toLowerCase().startsWith(lowerCase) ||
            aggregator.toLowerCase().startsWith(lowerCase) ||
            operator.operatorId.toLowerCase().startsWith(lowerCase) ||
            operator.aggregatorId.toLowerCase().startsWith(lowerCase) ||
            operator.detail.toLowerCase().includes(lowerCase) ||
            aggregator.toLowerCase().includes(lowerCase) ||
            operator.operatorId.toLowerCase().includes(lowerCase) ||
            operator.aggregatorId.toLowerCase().includes(lowerCase) ||
            (operator.originUrls.length &&
              operator.originUrls[0].toLowerCase().startsWith(lowerCase)) ||
            (operator.originUrls.length &&
              operator.originUrls[0].toLowerCase().includes(lowerCase))
          );
        return null;
      }
    );

    setFilteredOperators(filterEntries);
  };

  const onStatusChange = () => {
    let _selectedAgent = { ...selectedRow };
    if (!_selectedAgent) return;
    _selectedAgent.status =
      _selectedAgent.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
    // * update states

    API.post(
      `${API_MOD.OPERATOR}/status`,
      {
        operatorId: _selectedAgent.operatorId,
        aggregatorId: _selectedAgent.aggregatorId,
        status: _selectedAgent.status,
      },
      {
        headers: {
          Authorization: authUser?.token ?? "",
        },
      }
    )
      .then((response) => {
        let _filter = [...filteredOperators];
        const _i = _filter.findIndex(
          (x) => x.operatorId === selectedRow?.operatorId
        );
        _filter.splice(_i, 1, _selectedAgent);
        let _agents = { ...operatorState };
        // let i = _agents.findIndex((x) => x.operatorId === selectedRow?.operatorId);
        // _agents.splice(i, 1, _selectedAgent);
        _agents[_selectedAgent.operatorId] = _selectedAgent;
        setFilteredOperators(_filter);
        setOperatorState(_agents);
        setShowStatusDialog(false);
      })
      .catch((error) => {
        checkError(error, true, navigate);
      });
  };

  // DELETE FUNCTIONS

  // DELETE FUNCTIONS
  const onDelete = () => {
    if (!selectedRow) return;
    API.post(
      `${API_MOD.OPERATOR}/remove`,
      {
        operatorId: selectedRow?.operatorId,
        aggregatorId: selectedRow.aggregatorId,
      },
      {
        headers: {
          Authorization: authUser?.token ?? "",
        },
      }
    )
      .then((response) => {
        if (response) {
          let _agentObj = { ...operatorState };
          // let _index = _agentList.findIndex(
          //   (val) => val.operatorId === selectedRow?.operatorId
          // );
          // _agentList.splice(_index, 1);
          delete _agentObj[selectedRow?.operatorId];
          setOperatorState(_agentObj);
          toast.success("Operator Deleted successfully.", {
            bodyClassName: "text",
            className: "text",
            position: "bottom-right",
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        checkError(error, true, navigate);
      });
    setShowDeleteConfirm(false);
  };

  // const onCloseDelete = () => {
  //   setShowDeleteConfirm(false);
  // };
  const handleConfirmDelete = (rowData: OperatorDTO) => {
    setSelectedRow(rowData);
    setShowDeleteConfirm(true);
  };

  // const handleCreditEditDialog = (rowData: AgentDTO) => {
  //   setSelectedRow(rowData);
  //   setShowEditCreditDialog(true);
  // };
  //  EDIT FUNCTIONS

  // * Update API for origin URL
  const onEditOriginURL = (rowData: OperatorDTO | null) => {
    API.post(
      `${API_MOD.OPERATOR}/update`,
      {
        operatorId: rowData?.operatorId,
        originUrls: [editValue ?? rowData?.originUrls.toString()],
        aggregatorId: rowData?.aggregatorId,
        detail: editDetail ?? rowData?.detail,
        status: rowData?.status,
        rebateRate: rowData?.rebateRate,
        currency: rowData?.currency,
      },
      {
        headers: {
          Authorization: `${authUser?.token}`,
        },
      }
    )
      .then((res) => {
        if (res) {
          let _res = res?.data;
          if (_res) {
            let _agents = { ...operatorState };
            let _agentObj: OperatorDTO | any = { ...rowData };
            _agentObj["originUrls"] = _res.originUrls;
            // let _index = _agentList.findIndex((val) => {
            //   return val.operatorId === _res.refId;
            // });
            // _agentList.splice(_index, 1, _agentObj);
            _agents[_agentObj.operatorId] = _agentObj;
            setFilteredOperators(Object.values(_agents));
            setOperatorState(_agents);
            setShowEditDialog(false);
            showToast(
              "success",
              `Origin URL updated successfully for ${selectedRow?.operatorId}.`
            );
          }
        }
      })
      .catch((error) => {
        console.log(error, "error");
        checkError(error, true, navigate);
      });
  };

  const editOriginURLHeader = () => {
    return <h1 className={styles.dialogHeader}>Edit Origin URL</h1>;
  };

  const handleEditDialog = (rowData: OperatorDTO) => {
    setSelectedRow(rowData);
    setShowEditDialog(true);
  };

  const editRebateRateHeader = () => {
    return <h1 className={styles.dialogHeader}>Edit Rebate Rate</h1>;
  };

  // * Update API for rebate rate.
  const onEditRebateRate = (rowData: OperatorDTO | null) => {
    API.post(
      `${API_MOD.OPERATOR}/rebateRate`,
      {
        operatorId: rowData?.operatorId,
        rebateRate: editRebateRateValue ?? rowData?.rebateRate,
        aggregatorId: rowData?.aggregatorId,
      },
      {
        headers: {
          Authorization: `${authUser?.token}`,
        },
      }
    )
      .then((res) => {
        if (res) {
          let _res = res?.data;
          if (_res) {
            let _agentList = { ...operatorState };
            let _agentObj: OperatorDTO | any = { ...rowData };
            _agentObj["rebateRate"] = _res.rebateRate;
            // let _index = _agentList.findIndex((val) => {
            //   return val.operatorId === _res.refId;
            // });
            // _agentList.splice(_index, 1, _agentObj);
            _agentList[_agentObj.operatorId] = _agentObj;
            setFilteredOperators(Object.values(_agentList));
            setOperatorState(_agentList);
            setShowEditRebateRateDialog(false);

            showToast(
              "success",
              `Rebate Rate updated successfully for ${selectedRow?.operatorId}.`
            );
          }
        }
      })
      .catch((error) => {
        console.log(error, "error");
        checkError(error, true, navigate);
      });
  };

  const handleRebateRateEditDialog = (rowData: OperatorDTO) => {
    setSelectedRow(rowData);
    setShowEditRebateRateDialog(true);
  };

  // const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
  //   if (e.key === "Enter") {
  //     onSave();
  //   }
  // };

  const filterSearch = (e: ElementChangeEvent) => {
    let val = e.data?.toLowerCase();

    let filterResult = Object.values(operatorState ?? {}).filter((i) => {
      return (
        i.status.toLowerCase().startsWith(val) ||
        i.status.toLowerCase().includes(val)
      );
    });
    const _statusFilter = [STATUS.ACTIVE, STATUS.INACTIVE];
    const _environment = objToStringList(
      [STATUS.LIVE, STATUS.SIM],
      "Environment"
    );

    setFilterData({
      ...filterData,
      [FILTER.status]: { ...filterData.status, options: _statusFilter },
      environment: { ...filterData.environment, options: _environment },
    });
  };

  return (
    <>
      <div>
        <header className={"headerWrapper"}>
          <span className={styles.iconNameWrp}>
            <FiMenu
              onClick={() => setIsOpen(!isOpen)}
              size={40}
              className="burgerMenuIcon"
            />
            <span className={styles.nameWrp}>Operator Management</span>
          </span>
          <div className={styles.searchWrapper}>
            <FlatButton
              className={styles.filterIcon}
              onClick={() => setFilterShow(!filterShow)}
            >
              <FaFilter size={20} />
            </FlatButton>
            <SearchBar onChange={onChange} value={search} />

            <FlatButton
              icon={<MdPersonAddAlt1 size={20} />}
              label={isLessWidth() ? "" : "Add Operator"}
              severity="light"
              onClick={() => setShow(true)}
              className="addBtn"
            ></FlatButton>
          </div>
        </header>
        <div className={styles.actionWrapper}>
          <div className={styles.btnWrapper}>
            <Dialog
              closeOnEsc
              hideCloseBtn={false}
              show={showEditDialog}
              setShow={setShowEditDialog}
              headerTemplate={editOriginURLHeader}
              glassEffect={true}
            >
              <InputText
                name="detail"
                className={`${styles.input} ${styles.editDialogInput}`}
                type="text"
                label="Enter Name"
                labelClass={styles.label}
                onChange={(e) => setEditDetail(e.data)}
                placeholder="Enter Name"
                Icon={<FaUser size={20} />}
                defaultValue={selectedRow?.detail}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    onEditOriginURL(selectedRow);
                  }
                }}
              />
              <InputText
                name="callBackUrl"
                className={styles.input}
                type="text"
                label="Origin URL"
                labelClass={styles.label}
                onChange={(e) => setEditValue(e.data)}
                placeholder="Enter URL Here"
                Icon={<FaGlobe size={20} />}
                defaultValue={selectedRow?.originUrls}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    onEditOriginURL(selectedRow);
                  }
                }}
              />
              <div className={styles.buttonDiv}>
                <FlatButton
                  className={styles.dialogButton}
                  onClick={() => onEditOriginURL(selectedRow)}
                  label="UPDATE"
                />
                <FlatButton
                  className={styles.dialogButton}
                  label="CANCEL"
                  onClick={onClose}
                />
              </div>
            </Dialog>
          </div>
        </div>

        {/* STATUS */}
        <div className={styles.actionWrapper}>
          <div className={styles.btnWrapper}>
            <ConfirmDialog
              message={`Are you sure you want to change the status of aggregator ${
                selectedRow?.operatorId ?? ""
              } ?`}
              title="Change Status"
              show={showStatusDialog}
              onConfirm={onStatusChange}
              setShow={setShowStatusDialog}
              onClose={onClose}
            />
          </div>
        </div>

        <div className={styles.actionWrapper}>
          <div className={styles.btnWrapper}>
            <Dialog
              closeOnEsc
              hideCloseBtn={false}
              show={showEditRebateRateDialog}
              setShow={setShowEditRebateRateDialog}
              headerTemplate={editRebateRateHeader}
              glassEffect={true}
            >
              <InputText
                name="rebateRate"
                className={styles.input}
                type="text"
                label="Rebate Rate"
                labelClass={styles.label}
                onChange={(e) => setEditRebateRateValue(e.data)}
                placeholder="Rebate Rate"
                Icon={<FaPercent size={20} />}
                defaultValue={selectedRow?.rebateRate}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    onEditRebateRate(selectedRow);
                  }
                }}
              />
              <div className={styles.buttonDiv}>
                <FlatButton
                  className={styles.dialogButton}
                  onClick={() => onEditRebateRate(selectedRow)}
                  label="UPDATE"
                />
                <FlatButton
                  className={styles.dialogButton}
                  label="CANCEL"
                  onClick={onClose}
                />
              </div>
            </Dialog>
          </div>
        </div>
        {/* <ConfirmDialog
          message={`Are you sure you want to delete this operator ${selectedRow?.operatorId} ?`}
          title="Delete Operator ?"
          onConfirm={onDelete}
          setShow={setShowDeleteConfirm}
          show={showDeleteConfirm}
          confirmLabel="Delete"
          confirmSeverity="danger"
        /> */}
        {/* <Dialog
          closeOnEsc
          hideCloseBtn={false}
          show={showDeleteConfirm}
          setShow={setShowDeleteConfirm}
          headerTemplate={deleteHeader}
          glassEffect={true}
        >
          <h3 className={styles.dialogDeleteBody}>
            {`Are you sure you want to delete this operator ${selectedRow?.operatorId} ?`}
          </h3>
          <div className={styles.buttonDiv}>
            <FlatButton
              className={styles.dialogButton}
              onClick={() => onDelete(selectedRow)}
              label="DELETE"
            />
            <FlatButton
              className={styles.dialogButton}
              label="CANCEL"
              onClick={onCloseDelete}
            />
          </div>
        </Dialog> */}

        <ConfirmDialog
          message={`Are you sure you want to delete the Operator ${selectedRow?.detail} ?`}
          onConfirm={onDelete}
          setShow={setShowDeleteConfirm}
          show={showDeleteConfirm}
          title="Delete Operator"
          confirmLabel="Delete"
          confirmSeverity="danger"
        />
        <Dialog
          closeOnEsc
          hideCloseBtn={false}
          show={show}
          setShow={setShow}
          headerTemplate={dialogHeader}
          glassEffect={true}
        >
          <div className={styles.dialogBody}>
            <div className={styles.GridWrapper}>
              <div className={styles.section1}>
                <Dropdown
                  options={idOptions.options}
                  selectedValue={agentDetails?.aggregatorId ?? ""}
                  name="aggregatorId"
                  label="Aggregator ID"
                  placeholder="Enter Aggregator ID"
                  // valueObjKey="operatorId"
                  optionKey="aggregatorId"
                  returnValue="string"
                  onChange={handleChange}
                  disabled={idOptions.disabled}
                  optionTemplate={(e) => operatorOptTemplate(e, "aggregatorId")}
                />
                <div className={styles.generateIdAWrap}>
                  <InputText
                    name="operatorId"
                    className={styles.input}
                    type="text"
                    label="Operator ID"
                    labelClass={styles.label}
                    placeholder="Enter Operator ID"
                    onChange={handleChange}
                    value={agentDetails.operatorId}
                    Icon={<CgProfile size={20} />}
                  />

                  <button
                    className={styles.generatebutton}
                    onClick={() => generateUniqueId("operatorId", handleChange)}
                  >
                    {" "}
                    Generate
                  </button>
                </div>
                <InputText
                  name="detail"
                  type="text"
                  label="Operator Details"
                  labelClass={styles.label}
                  placeholder="Enter Operator Detail"
                  value={agentDetails.detail ?? ""}
                  onChange={handleChange}
                  Icon={<CgProfile size={20} />}
                />

                <Dropdown
                  options={agentStatus}
                  selectedValue={agentDetails.status ?? ""}
                  name="status"
                  label="Status"
                  placeholder="Select Status"
                  onChange={handleChange}
                />

                <InputText
                  name="rebateRate"
                  type="text"
                  label="Rebate Rate"
                  labelClass={styles.label}
                  placeholder="Enter In Percent"
                  value={agentDetails.rebateRate ?? ""}
                  Icon={<IoWalletOutline size={20} />}
                  onChange={handleChange}
                />
                <InputText
                  name="originUrls"
                  type="text"
                  label="Origin Url"
                  labelClass={styles.label}
                  placeholder="Enter URL"
                  value={agentDetails.originUrls?.toString() ?? ""}
                  onChange={handleChange}
                  Icon={<FaGlobe size={20} />}
                />
              </div>

              <div className="errorMsg">{errorMessage}</div>
              <div className={styles.buttonDiv}>
                <FlatButton
                  onClick={onSave}
                  className={styles.dialogButton}
                  label="SAVE"
                />
                <FlatButton
                  onClick={onClose}
                  className={styles.dialogButton}
                  label="CANCEL"
                />
              </div>
            </div>
          </div>
        </Dialog>

        <Filter
          show={filterShow}
          filterData={filterData}
          onFilterCHange={handleFilterChange}
          onFilterClick={onFilterClick}
          onClearClick={onClearClick}
          onFilter={filterSearch}
        />

        <PrimeTable
          data={filteredOperators}
          columns={OperatorColumn}
          ColumnBody={ColumnBody}
          isLoading={loader}
        />
      </div>
    </>
  );
};

export default AgentManagement;
